import React from 'react';
import Image from 'next/image';

interface IStarSection {
  rating: number;
}

const StarSection = ({ rating }: IStarSection) => {
  const printStars = () => {
    let remainingStars = rating;
    const elements = [];

    while (remainingStars > 0) {
      if (remainingStars >= 1) {
        remainingStars--;
        elements.push(
          <Image
            src="/icons/star-full.svg"
            alt="star empty"
            height={20}
            width={20}
            key={`star-${elements.length}`}
          />,
        );
      }

      if (remainingStars > 0 && remainingStars < 1) {
        remainingStars = 0;

        elements.push(
          <Image
            src="/icons/star-half.svg"
            alt="star empty"
            height={20}
            width={20}
            key={`star-${elements.length}`}
          />,
        );
      }
    }

    if (remainingStars == 0 && elements.length < 5) {
      while (elements.length < 5) {
        elements.push(
          <Image
            src="/icons/star-empty.svg"
            alt="star empty"
            height={20}
            width={20}
            key={`star-${elements.length}`}
          />,
        );
      }
    }
    return elements;
  };

  return (
    <div id="star-holder" className="flex gap-[2px]">
      {printStars()}
    </div>
  );
};

export default StarSection;
