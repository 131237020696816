import React from 'react';
import dynamic from 'next/dynamic';
import Link from 'next/link';

import { HeroSliderInterface } from '@/types/components';

import Wrapper from './Wrapper';
import Title from './Title';
import Subtitle from './Subtitle';
import StarSection from '../ReviewsSection/StarSection';

const HeroSlider = dynamic(
  () => import('hero-slider').then((module) => module),
  {
    ssr: false,
  },
);

const Slide = dynamic(
  () => import('hero-slider').then((module) => module.Slide),
  {
    ssr: false,
  },
);

const Nav = dynamic(() => import('hero-slider').then((module) => module.Nav), {
  ssr: false,
});

const Slider = ({
  data,
  heroImage,
  title,
  description,
}: HeroSliderInterface) => {
  return (
    <div className="min-h-[80vh]">
      <HeroSlider
        height="80vh"
        autoplay={!!data?.length}
        controller={{
          initialSlide: 1,
          slidingDuration: 300,
          slidingDelay: 100,
        }}
        accessability={{
          shouldDisplayButtons: false,
          shouldSlideOnArrowKeypress: false,
        }}
      >
        {data ? (
          data.map(({ id, description, photo, title }, index) => (
            <Slide
              key={id}
              background={{
                backgroundImageSrc: photo,
                backgroundImageAlt: `${title} slide image`,
              }}
            >
              <Wrapper>
                <Title>{title}</Title>
                <Subtitle>{description}</Subtitle>
                <Link
                  className="mt-6 sm:mt-2 flex gap-[8px] flex-wrap justify-center sm:justify-start cursor-pointer"
                  href="/#reviews-section"
                >
                  <StarSection rating={4.8} />
                  <p className="text-white">4.8 score based on 43 reviews</p>
                </Link>
              </Wrapper>
            </Slide>
          ))
        ) : (
          <Slide
            background={{
              backgroundImageSrc: heroImage,
              backgroundImageAlt: `${title} slide image`,
            }}
          >
            <Wrapper>
              <Title>{title}</Title>
              <Subtitle>{description}</Subtitle>
              <Link
                className="mt-6 sm:mt-2 flex gap-[8px] flex-wrap justify-center sm:justify-start cursor-pointer"
                href="/#reviews-section"
              >
                <StarSection rating={4.8} />
                <p className="text-white">4.8 score based on 43 reviews</p>
              </Link>
            </Wrapper>
          </Slide>
        )}

        <Nav activeColor="#E7CFA3" />
      </HeroSlider>
    </div>
  );
};

export default Slider;
