import React, { ReactNode } from 'react';
import Head from 'next/head';

interface HeadSeoInterface {
  title: string;
  description: string;
  children?: ReactNode;
}

const HeadSeo = ({ title, description, children }: HeadSeoInterface) => {
  return (
    <Head>
      <title>{title}</title>
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <meta name="description" content={description} />

      <meta property="og:locale" content="en_US" />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />

      {globalThis?.location?.origin && (
        <meta
          property="og:url"
          content={`${globalThis.location.origin}${globalThis.location.pathname}`}
        />
      )}
      {children}
    </Head>
  );
};

export default HeadSeo;
